body {
  touch-action: manipulation;
}

.root {
  display: flex;
}

.content {
  flex-grow: 1;
  position: relative;
  z-index: 10;
  padding-top: 58px;
}

/*drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },*/

@media (max-width: 600px) {
  .AppFixedElement {
    left: 0px;
  }

  header {
    height: 58px;
  }

  .hideOnMobile {
    display: none !important;
  }
  
  .blockOnMobile {
    display: block !important;
  }
}

@media (min-width: 600px) {
  .content {
    padding-top: 64px;
  }
}

@media (min-width: 600px) and (max-width: 999px) {
  .AppDrawerToggle {
    display: none !important;
  }

  .AppBar {
    margin-left: 56px;
    width: calc(100% - 56px) !important;
  }
  
  .AppDrawer {
    width: 56px;
  }

  .AppDrawerLogo {
    max-height: 40px;
  }

  .AppDrawerIcon {
    display: block !important;
  }

  .AppDrawerText {
    display: none !important;
  }

  .AppFixedElement {
    left: 56px;
  }
}

@media (min-width: 1000px) {
  .AppDrawerToggle {
    display: none !important;
  }

  .AppBar {
    margin-left: 240px;
    width: calc(100% - 240px) !important;
  }
  
  .AppDrawer {
    width: 240px;
  }

  .AppFixedElement {
    left: 240px;
  }
}

.MuiListSubheader-sticky-72,
.MuiListSubheader-sticky-95 {
}

.background {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  /*background-image: url('/img/background.jpg');*/
}

/* Animation */

.fadeInOut {
  -webkit-animation: fadeInOut 2s ease forwards;
  animation: fadeInOut 2s ease infinite;
}

@-webkit-keyframes fadeInOut {
0%,100% { opacity: 0.2; }
50% { opacity: 1; transform: scale(1.5); }
}

@keyframes fadeInOut {
0%,100% { opacity: 0.2; }
50% { opacity: 1; transform: scale(1.5); }
}